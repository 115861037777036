export const COMMUNITY_USER_EP_USER_HEADER_FRAGMENT = `
  fragment communityUserEpUserHeaderFragment on CommunityUser {
    id
    uid
    videoPresentationS3Url
    bannerFileResource {
      schemaCode
      path
    }
    pictureFileResource {
      schemaCode
      path
    }
    _connectionCount
    _sharedConnectionCount(myUid: "%authUser%")
    _isRecommendedForMe
    _followerCount
  }
`;
